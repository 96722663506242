import React from 'react';
import { Navigate } from 'react-router';
import DashboardLayout from '../layouts/dashboard';
import CSTicketsPage from '../pages/CSTickets.page';
import DevPage from '../pages/Development.page';
import LateOrdersReport from '../pages/LateOrdersReport.page';
import OrderPage from '../pages/Order.page';
import OrdersPage from '../pages/Orders.page';
import SignInPage from '../pages/SignIn.page';
import UnshippedOrders from '../pages/UnshippedOrders.page';
import { pagesAccess } from './accessMap';

export interface IMapItem {
  path: string;
  element: React.ReactNode;
  children?: IMapItem[];
}

export const siteMap = (originalAddress: string, getPageAccess: (pageUrl: keyof typeof pagesAccess) => boolean): IMapItem[] => [
  {
    path: '/dashboard',
    // element: (getPageAccess('/dashboard') ? <DashboardPage /> : <Navigate to='/oauth/signIn' />),
    element: (getPageAccess('/dashboard') ? <Navigate to='/customer-service/cs-tickets' /> : <Navigate to='/oauth/signIn' />),
  },

  {
    path: '/unshipped-orders',
    element: (getPageAccess('/unshipped-orders') ? <UnshippedOrders /> : <Navigate to='/oauth/signIn' />),
  },
  {
    path: '/late-orders-report',
    element: (getPageAccess('/late-orders-report') ? <LateOrdersReport /> : <Navigate to='/oauth/signIn' />),
  },
  {
    path: '/orders',
    element: getPageAccess('/orders') ? <DashboardLayout /> : <Navigate to='/oauth/signIn' />,
    children: [
      {
        path: '',
        element: <OrdersPage />,
      },
      {
        path: ':orderId',
        element: (getPageAccess('/orders/:orderId') ? <OrderPage /> : <Navigate to='/oauth/signIn' />),
      },
    ]
  },
  
  // -- 
  // -- Ouath routes
  // --
  {
    path: '/oauth/signIn',
    element:  <SignInPage />,
  },

  // --
  // -- Customer service
  // --
  {
    path: '/customer-service/cs-tickets',
    // element: (getPageAccess('/customer-service/cs-tickets') ? <CSTicketsPage /> : <Navigate to={`/oauth/signIn?original-url=${originalAddress}`} />),
    element: (getPageAccess('/customer-service/cs-tickets') ? <CSTicketsPage /> : <SignInPage originalUrl={originalAddress} />),
  },
    {
      path: '/customer-service/cs-tickets/:ticketId',
      // element: (getPageAccess('/customer-service/cs-tickets') ? <CSTicketsPage /> : <Navigate to={`/oauth/signIn?original-url=${originalAddress}`} />),
      element: (getPageAccess('/customer-service/cs-tickets') ? <CSTicketsPage /> : <SignInPage originalUrl={originalAddress} />),
    },
  
  // -- 
  // -- Development routes
  // --
  {
    path: '/dev',
    element:  (getPageAccess('/dashboard') ? <DevPage /> : <Navigate to='/oauth/signIn?original-url=' />),
  },

  // -- 
  // -- Users routes
  // --
  // {
  //   path: '/users',
  //   element:  (getPageAccess('/users') ? <UsersPage /> : <Navigate to='/oauth/signIn?original-url=/users' />),
  // },
  //   {
  //     path: '/users/new',
  //     element:  (getPageAccess('/users/new') ? <UserNewPage /> : <Navigate to='/oauth/signIn?original-url=/users/new' />),
  //   },
  //   {
  //     path: '/users/:idUser',
  //     element:  (getPageAccess('/users') ? <UserPage /> : <Navigate to='/oauth/signIn?original-url=/users' />),
  //   },
  
  // -- 
  // -- Other routes
  // --
  {
    path: '*',
    element: <Navigate to='/dashboard' />,
  },
];

